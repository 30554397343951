<template>
  <!-- Modal konfirmasi hapus data uang keluar-->
  <div id="modalHapusUangKeluar" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">Hapus Uang Keluar</h4>
          <button
            type="button"
            class="close"
            v-on:click="kembali"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="hapus">
          <div class="modal-body">
            <div class="row py-2">
              <div class="col-12">
                <p style="text-align: left">
                  Apakah Anda yakin menghapus uang keluar ini?
                </p>
              </div>
            </div>
            <div class="row py-2">
              <div class="col-6"></div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <label
                      v-on:click="kembali"
                      class="control-label-bold col-7 text-green ml-auto"
                      style="margin-top: 9px; text-align: right !important"
                    >
                      TIDAK
                    </label>
                  </div>
                  <div class="col-6">
                    <button
                      class="col-10 btn btn-green btn-block ml-auto"
                      style="margin-right: 17px"
                      :disabled="submitted"
                    >
                      <span v-html="btnSimpan"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
import $ from "jquery";
export default {
  name: "UangKeluarHapusModal",
  data() {
    return {
      btnSimpan: "YA",
      submitted: false,
    };
  },
  computed: {
      ...mapGetters(["stOpenUangKeluar"]),
  },
  mounted() {
  },
  methods: {
    ...mapActions(["actCashBankOutDelete", "actOpenUangKeluar"]),
    hapus() {
      this.submitted = true;
      this.btnSimpan = "<div class='fa fa-circle-notch fa-spin'></div>";
      if($.isEmptyObject(this.stOpenUangKeluar) == false) {
        var dataForCashBankOut = this.stOpenUangKeluar
            dataForCashBankOut.SynMode = 3,
        this.$store
            .dispatch("actCashBankOutDelete", dataForCashBankOut)
            .then((response) => {
            if (response.status=="success") 
            {
                setTimeout(() => this.$emit('reLoadPage'), 500);
            }
            this.submitted = false;
            this.btnSimpan = "YA";
            $("#modalHapusUangKeluar").modal("hide");
            this.$emit('forInformasiModal', {title: "Informasi", body:"Uang Keluar Berhasil Dihapus"});
            $("#modalKonfirmasi").modal("show");
            })
        .catch((error) => {
            console.log(error)
            this.submitted = false;
            this.btnSimpan = "YA";
        });
      }
    },
    kembali() {
        this.$store.dispatch("actOpenUangKeluar", null);
      $("#modalHapusUangKeluar").modal("hide");
    },
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ae2b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00ae2b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>