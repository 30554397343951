<template>
  <!-- Modal data produk-->
  <div id="modalAddUangKeluar" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-bolder">{{ modalTitle }}</h4>
          <button type="button" class="close" v-on:click="kembali" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row py-2">
            <div class="col-11">
              <form @submit.prevent="addUangKeluar">
                <div class="form-group row">
                  <label class="control-label-bold col-3 text-left" style="margin-top: 9px">Ambil Dari</label>
                  <div class="col-9">
                    <div class="form-group">
                      <select class="form-control" v-model="form.selectedCashBank">
                        <option value="" selected disabled>
                          Pilih Rekening
                        </option>
                        <option v-for="(dataBank, i) in masterCashBank" :key="i" :value="dataBank.AccountID + '.' + dataBank.DeviceNo">
                          {{ dataBank.BankName }} {{ dataBank.AccountNumber }} {{ dataBank.AccountName }}
                        </option>
                      </select>
                    <label class="mb-1 col-lg-12 text-left error" v-if="error_ambil_dari" style="margin-left: -140px;">
                      {{ error_ambil_dari }}
                    </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="control-label-bold col-3 text-left" style="margin-top: 9px">Dibayar ke</label>
                  <div class="col-9">
                    <input class="form-control" v-model="form.dibayar_ke" type="text" name="dibayar_ke" placeholder=""/>
                  </div>
                  <label class="mb-1 col-lg-12 text-left error" v-if="error_dibayar_ke">
                    {{ error_dibayar_ke }}
                  </label>
                </div>
                <div class="form-group row">
                  <label class="control-label-bold col-3 text-left" style="margin-top: 9px">Jumlah</label>
                  <div class="col-9">
                    <currency-input
                        type="text"
                        class="form-control text-right"
                        v-model="form.jumlah"
                        :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                        autocomplete="off"
                        autocorrect="off"
                    />
                  </div>
                  <label class="mb-1 col-lg-12 text-left error" v-if="error_jumlah">
                    {{ error_jumlah }}
                  </label>
                </div>
                <div class="form-group row">
                  <label class="control-label-bold col-3 text-left" style="margin-top: 9px">Keterangan</label>
                  <div class="col-9">
                    <input class="form-control" v-model="form.keterangan" type="text" placeholder=""/>
                  </div>
                  <label class="mb-1 col-lg-12 text-left error" v-if="error_keterangan">
                    {{ error_keterangan }}
                  </label>
                </div>
                <div class="form-group row">
                  <label class="control-label-bold col-3 text-left" style="margin-top: 9px" >Jenis</label>
                  <div class="col-9">
                    <div class="form-group">
                      <select class="form-control" v-model="form.jenis">
                        <option value="1" selected>
                          Biaya
                        </option>
                        <option value="2">
                          Non Biaya
                        </option>
                      </select>
                      <label class="mb-1 col-lg-12 text-left error" v-if="error_jenis" style="margin-left: -140px;">
                      {{ error_jenis }}
                    </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label v-on:click="kembali" class="control-label-bold col-7 text-green ml-auto" style="margin-top: 9px; text-align: right !important" >
                    Kembali
                  </label>
                  <button class="col-4 btn btn-green btn-block ml-auto" style="margin-right: 17px" :disabled="submitted">
                    <span v-html="btnSimpan"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CurrencyInput from '@/components/CurrencyInput_c';
import $ from "jquery";
export default {
  data() {
    return {
      modalTitle: "Tambah Uang Keluar",
      form: {
        selectedCashBank: "",
        dibayar_ke: "",
        jumlah: "",
        keterangan: "",
        jenis: "",
      },
      submitted: false,
      error_ambil_dari: null,
      error_dibayar_ke: null,
      error_jumlah: null,
      error_keterangan: null,
      error_jenis: null,
      btnSimpan: "Simpan",
    };
  },
  name: "UangKeluarAddModal",
  components: {
      CurrencyInput
  },
  methods: {
    ...mapActions(["actCashBankOutInsert", "actCashBankOutUpdate", "actOpenUangKeluar"]),
    addUangKeluar() {
      this.error_ambil_dari = null;
      this.error_jumlah = null;
      this.error_keterangan = null;
      this.error_jenis = null;
      this.error_dibayar_ke = null;
      this.submitted = true
      this.btnSimpan = "<div class='fa fa-circle-notch fa-spin'></div>";
      if (this.form.dibayar_ke == "" || this.form.jumlah == null || this.form.keterangan == "" || this.form.selectedCashBank == "" || this.form.jenis == "") {
        if (this.form.selectedCashBank == "") {
          this.error_ambil_dari = "Ambil dari harus dipilih";
        }
        if (this.form.dibayar_ke == "") {
          this.error_dibayar_ke = "Dibayar ke tidak boleh kosong";
        }
        if (this.form.jumlah == null) {
          this.error_jumlah = "Jumlah tidak boleh kosong";
        }
        if (this.form.keterangan == "") {
          this.error_keterangan = "Keterangan tidak boleh kosong";
        }
        if (this.form.jenis == "") {
          this.error_jenis = "Jenis harus dipilih";
        }
        this.submitted = false
        this.btnSimpan = "Simpan";
        return false;
      }
      //get data openclose outlet
      var opencloseoutletdata = null
      var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
      if(opencloseoutlet.length > 0){
        var opencloseoutletData = opencloseoutlet.filter((openData) => {
            return (openData.CloseDate == "" && openData.CloseTime == "" && openData.DeviceNo == this.deviceNo);
        });
        if(opencloseoutletData.length > 0) {
          opencloseoutletdata = opencloseoutletData[opencloseoutletData.length - 1]
        }
      }
      var AccountID = 0
      var AccountDeviceNo = 0
      if(this.form.selectedCashBank) {
            var selectedCashBank = this.form.selectedCashBank.split(".")
            AccountID = selectedCashBank[0]
            AccountDeviceNo = selectedCashBank[1]
      }
      var dataForCashBankOut = {}
      if($.isEmptyObject(this.stOpenUangKeluar) == true) {
        dataForCashBankOut = {
            AccountID: AccountID,
            AccountDeviceNo: AccountDeviceNo,
            PaidTo: this.form.dibayar_ke,
            Note: this.form.keterangan,
            Amount: this.form.jumlah,
            SpendingType: this.form.jenis,
            OpenID: opencloseoutletdata ? opencloseoutletdata.OpenID : 0,
            OpenDeviceNo: opencloseoutletdata ? opencloseoutletdata.DeviceNo : 0,
            IsAuto: "0",
            CreatedBy: localStorage.getItem("usernamekasir"),
            SynMode: 1,
        };
        this.$store
            .dispatch("actCashBankOutInsert", dataForCashBankOut)
            .then((response) => {
            if (response.status=="success") 
            {
                setTimeout(() => this.$emit('reLoadPage'), 1000);
            }
            this.resetForm()
            $("#modalAddUangKeluar").modal("hide");
            this.$emit('forInformasiModal', {title: "Informasi", body:"Uang Keluar Berhasil Disimpan"});
            $("#modalKonfirmasi").modal("show");
            })
            .catch((error) => {
            console.log(error)
            this.submitted = false
            this.btnSimpan = "Simpan";
            });
      } else {
        if($.isEmptyObject(this.stOpenUangKeluar) == false) {
          dataForCashBankOut = this.stOpenUangKeluar
          dataForCashBankOut.AccountID = AccountID
          dataForCashBankOut.AccountDeviceNo = AccountDeviceNo
          dataForCashBankOut.PaidTo = this.form.dibayar_ke
          dataForCashBankOut.Note = this.form.keterangan
          dataForCashBankOut.Amount = this.form.jumlah
          dataForCashBankOut.SpendingType = this.form.jenis
          dataForCashBankOut.OpenID = opencloseoutletdata ? opencloseoutletdata.OpenID : 0
          dataForCashBankOut.OpenDeviceNo = opencloseoutletdata ? opencloseoutletdata.DeviceNo : 0
          dataForCashBankOut.IsAuto = "0"
          dataForCashBankOut.EditedBy = localStorage.getItem("usernamekasir")
          dataForCashBankOut.SynMode = 2
        }
        this.$store
            .dispatch("actCashBankOutUpdate", dataForCashBankOut)
            .then((response) => {
            if (response.status=="success") 
            {
                setTimeout(() => this.$emit('reLoadPage'), 1000);
            }
            this.resetForm()
            $("#modalAddUangKeluar").modal("hide");
            this.$emit('forInformasiModal', {title: "Informasi", body:"Uang Keluar Berhasil Disimpan"});
            $("#modalKonfirmasi").modal("show");
            })
        .catch((error) => {
            console.log(error)
            this.submitted = false
            this.btnSimpan = "Simpan";
        });
      }
    },
    resetForm(){
        this.modalTitle = "Tambah Uang Keluar"
        this.submitted = false
        this.btnSimpan = "Simpan";
        this.form.selectedCashBank= '';
        this.form.dibayar_ke= '';
        this.form.jumlah= '';
        this.form.keterangan= '';
        this.form.jenis= '';
        this.error_ambil_dari= null;
        this.error_dibayar_ke= null;
        this.error_jumlah= null;
        this.error_keterangan= null;
        this.error_jenis= null;
    },
    editUangKeluar() {
        this.resetForm()
        if($.isEmptyObject(this.stOpenUangKeluar) == false) {
            this.modalTitle = "Edit Uang Keluar"
            this.form.selectedCashBank = this.stOpenUangKeluar.AccountID +'.'+ this.stOpenUangKeluar.AccountDeviceNo
            this.form.dibayar_ke = this.stOpenUangKeluar.PaidTo
            this.form.jumlah = this.stOpenUangKeluar.Amount
            this.form.keterangan = this.stOpenUangKeluar.Note
            this.form.jenis = this.stOpenUangKeluar.SpendingType
        }
    },
    kembali(){
        this.$store.dispatch("actOpenUangKeluar", null);
        this.resetForm()
        $("#modalAddUangKeluar").modal("hide");
    }
  },
  computed: {
    ...mapGetters(["cashbank", "deviceNo", "stOpenUangKeluar"]),
    masterCashBank() {
      var result = [];
      let tempBank = this.cashbank;
      result = tempBank.filter((item) => {
        return item.DeviceNo == this.deviceNo || item.AccountType == "2";
      });
      if (result.length > 0) {
        result.sort((a, b) =>
          a.AccountType > b.AccountType ? 1 : b.AccountType > a.AccountType ? -1 : 0
        );
      }
      return result;
    },
  },
  watch: {
    stOpenUangKeluar: function() {
        this.editUangKeluar()      
    }
  },
};
</script>
<style scoped>
.error {
  font-size: 0.8rem;
  transform: translateY(0%) translateX(25%);
  color: red;
}
</style>