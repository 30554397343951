<template>
  <div>
    <div class="page-container hide-list-menu">
      <div class="page-content">
        <!-- komponen header halaman-->
        <Header />
      </div>
    </div>
    <div class="container-fluid">
      <a href="#" v-on:click="kasir">Kasir</a> \ Uang Keluar
      <div class="row py-5">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <div class="row" style="padding-bottom: 15px">
            <div class="col-lg-3">
              <input
                class="form-control"
                type="date"
                id="dateStart"
                name="dateStart"
                v-model="dateStart"
              />
            </div>
            <div class="col-lg-3">
              <input
                class="form-control"
                type="date"
                id="dateEnd"
                name="dateEnd"
                v-model="dateEnd"
              />
            </div>
            <div class="col-lg-2">
              <button
                class="btn btn-green btn-block"
                v-on:click="getUangKeluar"
              >
                Proses
              </button>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-3" v-if="stSetUserTablet.AllowTambahUangKeluar == '1'">
              <button
                class="btn btn-green btn-block"
                v-on:click="addUangKeluar"
              >
                Tambah Uang Keluar
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordereless">
                <thead>
                    <tr style="background-color: lightgrey;">
                        <th>Jam</th>
                        <th>Ambil dari</th>
                        <th>Dibayar ke</th>
                        <th>Keterangan</th>
                        <th>Jumlah</th>
                        <th v-if="(stSetUserTablet.AllowEditUangKeluar == '1' || stSetUserTablet.AllowHapusUangKeluar == '1')"></th>
                    </tr>
                </thead>    
                <tbody v-for="(row, i) in dataUangKeluar" v-bind:key="i">
                    <tr>
                        <td class="text-left">
                            <b>{{ convertDateDBtoIndo(row.TransactionDate) }}</b>
                        </td>
                        <td colspan="5"></td>
                    </tr>
                    <tr v-for="(rox, j) in dataUangKeluar[row.TransactionDate]" v-bind:key="j">
                        <td>
                            {{ rox.TransactionTime }}
                        </td>
                        <td>{{ rox.AccountName }}</td>
                        <td>{{ rox.PaidTo }}</td>
                        <td>{{ rox.Note }}</td>
                        <td>Rp {{ $filters.numberTocurrencyIDR(rox.Amount) }}</td>
                        <td style="text-align: center; width: 160px;" v-if="(stSetUserTablet.AllowEditUangKeluar == '1' || stSetUserTablet.AllowHapusUangKeluar == '1')">
                            <button
                                class="btn btn-link"
                                type="button"
                                @click="editUangKeluar(rox)" :disabled="submitted" 
                                v-if="stSetUserTablet.AllowEditUangKeluar == '1'" >
                                Edit
                            </button>
                            <button
                                style="color: red !important;"
                                class="btn btn-link"
                                type="button"
                                @click="hapusUangKeluar(rox)" :disabled="submitted" 
                                v-if="stSetUserTablet.AllowHapusUangKeluar == '1'" >
                                Hapus
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <UangKeluarAddModal @reLoadPage="getUangKeluar" @forInformasiModal="forInformasiModal"/>
    <UangKeluarHapusModal @reLoadPage="getUangKeluar" @forInformasiModal="forInformasiModal"/>
    <KonfirmasiModal :datakonfirmasi="datakonfirmasi"/>
  </div>
</template>
<script>
/* eslint-disable */ //disabled eslint compiler
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import Header from "@/components/Header_c.vue";
import UangKeluarAddModal from "@/components/uang_keluar/UangKeluarAddModal.vue";
import UangKeluarHapusModal from "@/components/uang_keluar/UangKeluarHapusModal.vue";
import KonfirmasiModal from "@/components/konfirmasi_modal/KonfirmasiModal_c.vue";
export default {
  name: "UangKeluar",
  components: {
    Header,
    UangKeluarAddModal,
    UangKeluarHapusModal,
    KonfirmasiModal
  },
  data() {
    return {
      submitted: false,
      dateStart: null,
      dateEnd: null,
      dataUangKeluar: [],
      datakonfirmasi:{
          title:"",
          body:""
      },
    };
  },
  methods: {
    ...mapActions(["actGetRiwayatUangKeluar", "actOpenUangKeluar"]),
    kasir() {
      this.$router.push({ name: "Kasir" });
    },
    forInformasiModal(data){
      this.datakonfirmasi.title = data.title
      this.datakonfirmasi.body = data.body
    },
    setDateFilter() {
      var newDate = new Date();
      var tgl = (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
      var bln =
        (newDate.getMonth() + 1 < 10 ? "0" : "") + (newDate.getMonth() + 1);
      var th = newDate.getFullYear();
      this.dateStart = th + "-" + bln + "-" + tgl;
      this.dateEnd = th + "-" + bln + "-" + tgl;
      this.getUangKeluar();
    },
    getUangKeluar() {
        var data = {
            dt1: this.dateStart,
            dt2: this.dateEnd,
        };
        this.$store.dispatch("actGetRiwayatUangKeluar", data);
    },
    showRiwayatUangKeluar() {
      var filterData = this.stRiwayatUangKeluar.DaftarUangKeluar;
      /*filterData = filterData.filter((el) => {
        return el.PaidTo != 'Manajemen';
      });*/
      var riwayat = filterData.reduce((a, c) => {
        let filtered = a.filter((el) => el.TransactionDate === c.TransactionDate);
        if (filtered.length > 0) {
        } else {
          a.push(c);
        }
        return a;
      }, []);

      if (riwayat) {
        riwayat.sort((a, b) =>
          new Date(a.TransactionDate) > new Date(b.TransactionDate) ? -1 : new Date(b.TransactionDate) > new Date(a.TransactionDate) ? 1 : 0);
        this.dataUangKeluar = riwayat;
        for (let i = 0; i < riwayat.length; i++) {
          this.dataUangKeluar[riwayat[i].TransactionDate] = filterData.filter(
            (el) => {
              return el.TransactionDate == riwayat[i].TransactionDate;
            }
          );
        }
      }
    },
    addUangKeluar(){
        $("#modalAddUangKeluar").modal('show');
    },
    editUangKeluar(data){
        this.$store.dispatch("actOpenUangKeluar", data)
        .then((response) => {
            if (response.status=="OK") {
              $("#modalAddUangKeluar").modal("show")    
            }
        })
    },
    hapusUangKeluar(data){
        this.$store.dispatch("actOpenUangKeluar", data)
        .then((response) => {
            if (response.status=="OK") {
              $("#modalHapusUangKeluar").modal("show")    
            }
        })
    },
    convertDateDBtoIndo(string) {
        var bulanIndo = ['', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September' , 'Oktober', 'November', 'Desember'];
        
        var tanggal = string.split("-")[2];
        var bulan = string.split("-")[1];
        var tahun = string.split("-")[0];
        
        return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
  },
  computed: {
    ...mapGetters(["stRiwayatUangKeluar", "deviceNo", "stSetUserTablet"]),
  },
  mounted() {
    this.setDateFilter();
  },
  watch: {
    stRiwayatUangKeluar: function () {
      this.showRiwayatUangKeluar();
    },
  },
};
</script>